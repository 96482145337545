<template>
  <div>
    <van-nav-bar
      class="backBox"
      title="我的优惠券"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <!-- 优惠券 -->

    <div class="page">
      <header>
<div class="query_box">
          <div class="query_box_item">
            <span class="query_box_item_tit">车牌号码</span>
            <div class="query_box_item_right">
              <div class="section">
                <span class="selPlateNum" @click="openVhPopup">{{
                  selVhVal ? selVhVal : '选择车牌号码'
                }}</span>
                <img
                  src="../../../assets/img/rightjt@2x.png"
                  class="rightIcon"
                />
               <van-popup
              v-model="showVhPopup"
              @close="onVhClose"
              position="bottom"
            >
              <van-picker
                :columns="vhColumns"
                @change="onVhChange"
                @confirm="onVhConfirm"
                @cancel="onVhCancel"
                show-toolbar
                title="选择车牌号码"
                visible-item-count="5"
                default-index="0"
              />
            </van-popup>
              </div>
            </div>
          </div>
          <!-- <div class="query_box_item">
            <span class="query_box_item_tit">优惠券类型</span>
            <div class="query_box_item_right">
              <div class="section">
                <span class="selPlateNum" @click="openTypePopup">{{
                  selTypeVal ? selTypeVal : '选择优惠券类型'
                }}</span>
                <img
                  src="../../../assets/img/rightjt@2x.png"
                  class="rightIcon"
                />
                <van-popup
                  v-model="showTypePopup"
                  @close="onTypeClose"
                  position="bottom"
                >
                  <van-picker
                    :columns="couponTypeColumns"
                    @change="onTypeChange"
                    @confirm="onTypeConfirm"
                    @cancel="onTypeCancel"
                    show-toolbar
                    title="选择优惠券类型"
                    visible-item-count="5"
                    default-index="0"
                  />
                </van-popup>
              </div>
            </div>
          </div> -->
          <!-- <div class="query_box_item">
            <span class="query_box_item_tit">优惠券状态</span>
            <div class="query_box_item_right">
              <div class="section">
                <span class="selPlateNum" @click="openStatusPopup">{{
                  selStatusVal ? selStatusVal : '选择优惠券状态'
                }}</span>
                <img
                  src="../../../assets/img/rightjt@2x.png"
                  class="rightIcon"
                />
                <van-popup
                  v-model="showStatusPopup"
                  @close="onStatusClose"
                  position="bottom"
                >
                  <van-picker
                    :columns="couponStatusColumns"
                    @change="onStatusChange"
                    @confirm="onStatusConfirm"
                    @cancel="onStatusCancel"
                    show-toolbar
                    title="选择优惠券状态"
                    visible-item-count="5"
                    default-index="0"
                  />
                </van-popup>
              </div>
            </div>
          </div> -->

        </div>

      </header>
      <div class="box_2">
        <van-tabs :active="active" @change="onChange">
          <van-tab title="现有优惠券">
            <span class="text_3">共{{ couponHaveList.length }}张</span>
            <div class="section_6">
              <!-- <div class="{{styleType === 1 ? 'pointOne' : styleType === 2 ? 'pointTwo' : styleType === 3 ? 'pointThree' : styleType === 5 ? 'pointFive' : ''}}  {{slippedUp ? 'narrow' : 'enlarge'}}"></div> -->

              <div
                :class="[
                  'box_4',
                  getClassByType(item)
                ]"
                v-for="item in couponHaveList"
                :key="item.id"
                @click="openCouponDetail(item,1)"
              >
                <div class="leftBox">
                  <div
                    :class="[
                      'text-group_13',
                      getClassByType(item)
                    ]"
                  >
                    <div class="text-wrapper_1">
                      <span class="text_4">{{
                        item.type == 1 || item.type == 11
                          ? item.value / 100
                          : item.type == 3 || item.type == 13
                          ? 1
                          : item.value
                      }}</span>
                      <span class="text_5">{{getUnitByType(item)}}</span>

                    </div>
                    <span class="text_33">{{ item.vh_p_number }}</span>
                  </div>
                  <div class="lineline"></div>
                </div>
                <div class="rightBox">
                  <div class="text-group_14">
                    <span class="text_6">{{getNameByType(item)}}</span>
                    <span class="text_7">银湖停车场</span>
                    <span
                      decode="true"
                      class="text_8"
                      v-if="
                        !judgeEndDateLocal(item.e_e_time) &&
                        !judgeStartDateLocal(item.e_s_time)
                      "
                      >有效期：{{timeFormatter(item.e_s_time) }}&nbsp;至&nbsp;{{
                       timeFormatter(item.e_e_time)
                      }}</span
                    >
                    <span decode="true" class="text_8" v-else
                      >有效期：长期有效</span
                    >
                  </div>
                </div>
              </div>
              <!-- 空状态 -->
              <van-empty
                v-if="couponHaveList.length == 0"
                description="您还没有优惠券"
              />
              <van-empty
                v-if="couponHaveList.length !== 0 && isError"
                image="error"
                description="优惠券查询异常"
              />
            </div>
          </van-tab>
          <van-tab title="已使用/过期">
            <span class="text_3">共{{ couponDoneList.length }}张 </span>
            <div class="section_6">
              <div
                :class="[
                  'box_4','overDate',
                  getClassByType(item)
                ]"
                v-for="item in couponDoneList"
                :key="item.id"
                @click="openCouponDetail(item,2)"
              >
                <div class="leftBox">
                  <div
                    :class="[
                      'text-group_13',
                      getClassByType(item)
                    ]"
                  >
                    <div class="text-wrapper_1">
                      <span class="text_4">{{
                        item.type == 1 || item.type == 11 ? item.value / 100 : item.value
                      }}</span>
                      <span class="text_5">{{getUnitByType(item)}}</span>
                    </div>
                    <span class="text_33">{{ item.vh_p_number }}</span>
                  </div>
                  <div class="lineline"></div>
                </div>
                <div class="rightBox">
                  <div class="text-group_14">
                    <span class="text_6">{{getNameByType(item)}}</span>
                    <span class="text_7">银湖停车场</span>
                    <span
                      decode="true"
                      class="text_8"
                      v-if="
                        !judgeEndDateLocal(item.e_e_time) &&
                        !judgeStartDateLocal(item.e_s_time)
                      "
                      >有效期：{{timeFormatter(item.e_s_time) }}&nbsp;至&nbsp;{{
                       timeFormatter(item.e_e_time)
                      }}</span
                    >
                    <span decode="true" class="text_8" v-else
                      >有效期：长期有效</span
                    >
                  </div>
                </div>
                <!-- 过期图标 -->
                <!-- status                    | int              | yes      | 1：已领取未使用 2:已使用 3: 已回收 -->
                <div class="box_gq">
                  <div
                    :class="[
                      'box_10',
                      getClassByType(item)
                    ]"
                  >
                    <div
                      :class="[
                        'text-wrapper_9',
                        getClassByType(item)
                      ]"
                    >
                      <span
                        class="text_27"
                        :class="[
                          'text_27',
                          getClassByType(item)
                        ]"
                      >
                        {{
                          item.status === 2
                            ? '使用'
                            : item.status === 3
                            ? '回收'
                            : item.status === 4
                            ? '过期'
                            : ''
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 空状态 -->
              <van-empty
                v-if="couponDoneList.length == 0"
                description="暂无匹配数据"
              />
              <van-empty
                v-if="couponDoneList.length !== 0 && isError"
                image="error"
                description="查询异常"
              />
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>

    <van-toast id="van-toast" />
  </div>
</template>
<script>
import * as util from '../../../api/util'
import * as common from '../../../api/common'
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
import { Dialog } from 'vant'
export default {
  data () {
    return {
      getTokenInfo: {},
      active: 0,
      total: 0,
      // currentPage:1,
      page_no: 1,
      page_size: 20,
      couponDoneList: [
        // {
        //   id: 0,
        //   type: 1,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   status: 4,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 1,
        //   type: 2,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   status: 4,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 2,
        //   type: 3,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   status: 2,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 3,
        //   type: 4,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   status: 2,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 4,
        //   type: 4,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   status: 3,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 5,
        //   type: 4,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   status: 4,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 6,
        //   type: 4,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   status: 4,
        //   vh_p_number: '浙A12345'
        // }
      ],
      couponHaveList: [
        // {
        //   id: 0,
        //   type: 1,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 1,
        //   type: 2,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 2,
        //   type: 3,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 3,
        //   type: 4,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 4,
        //   type: 4,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 5,
        //   type: 4,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   vh_p_number: '浙A12345'
        // },
        // {
        //   id: 6,
        //   type: 4,
        //   e_s_time: '2020-12-12 12:12:12',
        //   e_e_time: '2030-12-12 12:12:12',
        //   value: 99,
        //   vh_p_number: '浙A12345'
        // }
      ],
      isError: false,
      showVhPopup: false,
      selVhVal: '',
      showTypePopup: false,
      selTypeVal: '',
      showStatusPopup: false,
      selStatusVal: '',
      couponTypeColumns: ['全部', '金额减免券', '时长减免券', '折扣减免券', '全免券'],
      couponStatusColumns: ['全部', '未使用', '已使用', '已回收', '已过期']

    }
  },
  mounted () {
    console.log('tab', this.$route.query)
    if (!this.selVhVal || this.selVhVal === '') {
      this.selVhVal = this.vhColumns[0]
    }
    if (this.$route.query.tab) {
      this.active = Number(this.$route.query.tab)
    }
    this.$nextTick(() => {
      if (!this.vhColumns || this.vhColumns.length === 0) {
        Dialog.confirm({
          title: '提示',
          message: '您还没有绑定车辆，是否前往绑定？'
        })
          .then(() => {
          // on confirm
            this.$router.push('/myCar')
          })
          .catch(() => {
          // on cancel
          })
      } else {
        this.get_coupon(this.active)
      }
    })
  },
  computed: {
    ...mapState({
      sid: state => state.sid.sid,
      myCarList: state => state.myCarList.myCarList,
      channelPath: state => state.channelPath.channelPath

    }),
    ...mapGetters(['vhColumns']

    )

  },
  methods: {
    // 根据优惠券Type获取对应的名称
    getNameByType (item) {
      if (!item || !item.type) return ''
      return util.getNameByCouponType(item.type)
    },
    // 根据优惠券Type获取对应的样式class
    getClassByType (item) {
      if (!item || !item.type) return ''
      return util.getClassByCouponType(item.type)
    },
    // 根据优惠券Type获取对应的样式单位
    getUnitByType (item) {
      if (!item || !item.type) return ''
      return util.getUnitByCouponType(item.type)
    },
    timeFormatter (para) {
      return common.format(new Date(para), 'yyyy-MM-dd hh:mm:ss')
    },
    onVhChange () {

    },
    onTypeChange () {

    },
    onStatusChange () {

    },
    onVhConfirm (value) {
      if (value) {
        this.selVhVal = value
        this.showVhPopup = false
      }
      this.get_coupon(this.active)
    },
    onTypeConfirm (value) {
      this.selTypeVal = value
      this.showTypePopup = false
      console.log(value)
      console.log(util.getCouponValByname(value))
      this.get_coupon(this.active)
    },
    onStatusConfirm (value) {
      this.selStatusVal = value
      this.showStatusPopup = false
      console.log(value)
      this.get_coupon(this.active)
    },
    onVhCancel () {
      this.showVhPopup = false
    },
    onTypeCancel () {
      this.showTypePopup = false
    },
    onStatusCancel () {
      this.showStatusPopup = false
    },
    onVhClose () {

    },
    onTypeClose () {

    },
    onStatusClose () {

    },
    // 打开选择车牌弹出层
    openVhPopup () {
      console.log('打开车牌弹出层')
      this.showVhPopup = true
    },
    openTypePopup () {
      console.log('打开type弹出层')
      this.showTypePopup = true
    },
    openStatusPopup () {
      console.log('打开status弹出层')
      this.showStatusPopup = true
    },
    async get_coupon (type) {
      console.log('get_coupon', type)
      var para = {
        v: '1.0',
        app_key: 'd7ca0abc-6b4b-44e4-bc71-7fd329ce2f7a',
        app_pass: '17f6e649-d26f-488f-b03b-5676aa3be362',
        sid: this.sid,
        vh_p_number: this.selVhVal,
        page_no: 1,
        page_size: 1000
      }
      try {
        if (this.selTypeVal) {
          para.type = util.getCouponValByname(this.selTypeVal)
        }
        if (type === 0) {
          // para.status = util.getCouponStatusName(this.selStatusVal)
          para.status = 1
        }

        var res = await axios({
          url: common.codeUrl + 'enterprise.coupon.list_by_vh',
          method: 'POST',
          data: para,
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        console.log('查询参数', para)
        this.$toast.clear()
        if (res.data.code && res.data.code === 10000) {
          console.log('我的优惠券', res)
          if (res.data.list && res.data.list.length) {
            if (type === 0) {
            // 直接赋值

              this.couponHaveList = res.data.list

              // const arr = []
              // // 只需要Status为2和3的数据
              // data.forEach((x) => {
              //   if (x.status !== 1) {
              //     arr.push(x)
              //   }
              // })
              // this.couponDoneList = arr
            } else {
            // 遍历移出status===1的数据
              const usedCouponList = []
              for (let x = 0; x < res.data.list.length; x++) {
                if (res.data.list[x].status !== 1) {
                  usedCouponList.push(res.data.list[x])
                }
              }
              this.couponDoneList = usedCouponList
            }
            // if (visible === true) {
            //   // this.$toast()
            //   // this.$toast.close();

          //   // this.visible = true
          // }
          // res.data.list.forEach((val, index) => {
          //   val.typeName = this.couponTypeLabel[val.type]
          //   if (val.all_free) {
          //     val.typeName += '(全免)'
          //   }
          //   if (val.type === this.couponType.money ||
          //     val.type === this.couponType.moneyActual) {
          //     val.value /= 100
          //   } else if (val.type === this.couponType.time) {
          //   } else if (val.type === this.couponType.disBreaks ||
          //     val.type === this.couponType.disBreaksActual) {
          //     val.value /= 10
          //   } else if (val.type === this.couponType.free) {
          //   }
          //   val.expire_time = common.format(val.expire_time, 'yyyy-M-dd')
          //   val.granted_time = common.format(val.granted_time, 'yyyy-M-dd')
          // })
          // this.$nextTick(() => {
          //   this.list = res.data.list
          //   if (!visible) this.getCoupons(res.data.list[0])
          // })
          } else {
            this.couponHaveList = []
            this.couponDoneList = []
            this.couponPrice1 = '未获取到优惠券'
          }
        } else {
          this.couponHaveList = []
          this.couponDoneList = []
          this.$toast.fail('获取优惠券失败，请重试')
        }
      } catch (e) {
        console.log('error', e)
        // test
        // const data = [
        //   {
        //     coupon_id: 'YX45SX169094037764134444134',
        //     rule_id: 1999,
        //     value: 10,
        //     vh_p_number: '浙A3GB67',
        //     status: 1,
        //     effect_type: 1,
        //     expire_time: '2023-08-03T09:39:00+08:00',
        //     granted_time: '2023-08-02T09:39:29+08:00',
        //     latest_used_time: null,
        //     reverted_time: '2023-08-04T00:45:00.047988+08:00',
        //     used_times: null,
        //     e_s_time: '1970-01-01T00:00:00+08:00',
        //     e_e_time: '2999-12-31T00:00:00+08:00',
        //     allow_s_time: '00:00:00',
        //     allow_e_time: '23:59:59',
        //     type: 3,
        //     id: 645085,
        //     sid: 'YX45SX',
        //     category: 1,
        //     actor_name: '',
        //     biz_id: 2274,
        //     biz_name: '车场22',
        //     allowed_used_onetime_self: 1,
        //     allowed_used_onetime_parent: 1,
        //     customer_type: 4,
        //     alias_name: '车场22',
        //     rule_name: ''
        //   },
        //   {
        //     coupon_id: 'YX45SX169094037764134444135',
        //     rule_id: 1999,
        //     value: 10,
        //     vh_p_number: '浙A3GB67',
        //     status: 3,
        //     effect_type: 1,
        //     expire_time: '2023-08-03T09:39:00+08:00',
        //     granted_time: '2023-08-02T09:39:29+08:00',
        //     latest_used_time: null,
        //     reverted_time: '2023-08-04T00:45:00.047988+08:00',
        //     used_times: null,
        //     e_s_time: '1970-01-01T00:00:00+08:00',
        //     e_e_time: '2999-12-31T00:00:00+08:00',
        //     allow_s_time: '00:00:00',
        //     allow_e_time: '23:59:59',
        //     type: 3,
        //     id: 645086,
        //     sid: 'YX45SX',
        //     category: 1,
        //     actor_name: '',
        //     biz_id: 2274,
        //     biz_name: '车场22',
        //     allowed_used_onetime_self: 1,
        //     allowed_used_onetime_parent: 1,
        //     customer_type: 4,
        //     alias_name: '车场22',
        //     rule_name: ''
        //   }
        // ]
      }
    },
    judgeStartDateLocal (date) {
      console.log('ggggg', date)
      console.log('ggggg', util.judgeStartDateLong(date))
      return util.judgeStartDateLong(date)
    },
    judgeEndDateLocal (date) {
      return util.judgeEndDateLong(date)
    },

    onChange (value) {
      this.active = value
      this.get_coupon(this.active)
    },
    openCouponDetail (row, type) {
      const encodedItem = encodeURIComponent(JSON.stringify(row))

      this.$router.push({ name: 'myCouponDetail', params: { type: type, item: encodedItem, tab: this.active } })
    },
    onClickLeft () {
      // this.$router.go(-1)
      this.$router.replace(
        {
          path: '/index',
          query: {
            sid: this.sid,
            channel_path: this.channelPath
          }
        })
    }

  }
}
</script>
<style lang="scss" scoped>

header{
   .query_box {
    // width: 3.43rem;
    // height: 5rem;
    display: flex;
    flex-direction: column;
    // margin-bottom: 0.12rem;
    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      /* width: 686rpx; */
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .selPlateNum {
            font-size: 0.14rem;
          }
        }
      }
    }

    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .showCommon {
            font-size: 0.14rem;
          }
          .rightIcon {
            width: 0.08rem;
            height: 0.14rem;
            margin: 0.03rem 0 0.03rem 0.05rem;
          }
        }
        .image_4-1 {
          position: absolute;
          left: 0;
          top: -0.01rem;
          width: 3.42rem;
          height: 0.01rem;
          background-color: #f0f0f0;
        }
      }
    }
  }
}
.page {

  overflow-x: hidden;
  overflow-y: auto;
}
.box_2 {
  // background-color: rgba(255, 255, 255, 1);
  width: 3.43rem;
  align-self: center;
  margin-top: 0.08rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.16rem;
  // padding: 0.08rem 0.05rem 0.19rem 0.07rem;
  .text_3 {
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 0.14rem;
    font-family: PingFangSC-Regular;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    line-height: 0.2rem;
    margin: 0 2.93rem 0 0.01rem;
  }
  .section_6,
  .section_7 {
    margin-top: 0.05rem;
    display: flex;
    flex-direction: column;
    .box_4 {
      background-size: 100% 100%;
      margin-top: 0.1rem;
      flex-direction: row;
      display: flex;
      align-items: center;
      border-radius: 0.04rem;
      position: relative;
      // background-color: pink;
      // background-color: rgba(255, 255, 255, 0.6);
      // background-color: lightgrey;
      // color: lightgrey; /* 或者使用十六进制颜色值 #e0e0e0 */

      .leftBox {
        background-image: radial-gradient(
            circle at right top,
            #fff,
            #fff 0.05rem,
            transparent 0.05rem
          ),
          radial-gradient(
            circle at right bottom,
            #fff,
            #fff 0.05rem,
            transparent 0.05rem
          );
        padding: 0.07rem 0.16rem 0.07rem 0.1rem;
        position: relative;

        /* border-right: 1rpx dashed #F2F2F2; */
        /* background: radial-gradient(30rpx at right top, transparent 50%, #1889F6 52%); */
        /* background: radial-gradient(30rpx at right bottom, transparent 50%, #1889F6 52%); */

        .text-group_13 {
          display: flex;
          flex-direction: column;
          margin: 0.08rem 0 0.13rem 0;
          /* background-image: radial-gradient(circle at right top, #fff, #fff 15px, transparent 16px), radial-gradient(circle at right bottom, #fff, #fff 15px, transparent 16px);
  border-right: 1px dashed #FFF; */
          .text-wrapper_1 {
            /* width: 90rpx; */
            height: 0.29rem;
            overflow-wrap: break-word;
            font-size: 0;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            white-space: nowrap;
            line-height: 0.29rem;
            margin: 0 0.05rem 0 0.06rem;
            .text_4 {
              overflow-wrap: break-word;
              // color: rgba(241, 198, 2, 1);
              font-size: 0.24rem;
              font-family: PingFangSC-Medium;
              font-weight: 500;
              text-align: left;
              white-space: nowrap;
              line-height: 0.29rem;
            }

            .text_5 {
              overflow-wrap: break-word;
              // color: rgba(241, 198, 2, 1);
              font-size: 0.14rem;
              font-family: PingFangSC-Medium;
              font-weight: 500;
              text-align: left;
              line-height: 0.29rem;
            }
          }

          .text_33 {
            overflow-wrap: break-word;
            // color: rgba(241, 198, 2, 1);
            font-size: 0.11rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: center;
            white-space: nowrap;
            line-height: 0.16rem;
            margin-top: 0.04rem;
          }
        }
        .couponTextOne {
          color: #f1c602;
        }
        .couponTextTwo {
          color: #0ea3ed;
        }
        .couponTextThree {
          color: #2778ec;
        }
        .couponTextFour {
          color: #e98740;
        }
        .lineline {
          width: 0.01rem;
          height: 0.49rem;
          border-left: 0.01rem dashed #f3ccd2;
          /* margin-left: 30rpx; */
          position: absolute;
          top: 0.17rem;
          right: 0;
        }
      }

      // .section_7 .leftBox {
      //   background-image: radial-gradient(
      //       circle at right top,
      //       #fff,
      //       #fff 0.05rem,
      //       transparent 0.05rem
      //     ),
      //     radial-gradient(
      //       circle at right bottom,
      //       #fff,
      //       #fff 0.05rem,
      //       transparent 0.05rem
      //     );
      //   padding: 0.07rem 0.16rem 0.07rem 0.1rem;
      //   position: relative;
      // }

      .rightBox {
        background-image: radial-gradient(
            circle at left top,
            #fff,
            #fff 0.05rem,
            transparent 0.05rem
          ),
          radial-gradient(
            circle at left bottom,
            #fff,
            #fff 0.05rem,
            transparent 0.05rem
          );
        padding: 0.07rem 0rem 0.07rem 0;
        /* background: radial-gradient(30rpx at left top, transparent 50%, #1889F6 52%),radial-gradient(30rpx at left bottom, transparent 50%, #1889F6 52%); */
        /* background: radial-gradient(30rpx at left bottom, transparent 50%, #1889F6 52%); */
        .text-group_14 {
          display: flex;
          flex-direction: column;
          margin: 0.04rem 0 0.04rem 0.14rem;
          /* background-image: radial-gradient(circle at left top, #fff, #fff 15px, transparent 16px), radial-gradient(circle at left bottom, #fff, #fff 15px, transparent 16px); */
          .text_6 {
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1);
            font-size: 0.14rem;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            white-space: nowrap;
            line-height: 0.2rem;
            margin-right: 1.05rem;
          }
          .text_7 {
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 0.11rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.16rem;
            margin: 0.05rem 1.2rem 0 0;
          }
          .text_8 {
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 0.08rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.16rem;
            margin-top: 0.05rem;
          }
        }
      }

      /* 过期图标 */
      .box_gq {
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0.01rem;
        top: 0;
        padding: 0.09rem 0.07rem 0.37rem 2.79rem;
        .box_10 {
          border-radius: 0.25rem;
          // border: 1px solid rgba(241, 198, 2, 1);
          display: flex;
          flex-direction: column;
          padding: 0.04rem;
          .text-wrapper_9 {
            border-radius: 0.25rem;
            display: flex;
            flex-direction: column;
            padding: 0.07rem 0.04rem 0.09rem 0.05rem;

            .text_27 {
              overflow-wrap: break-word;
              font-size: 0.12rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
            }
            .couponTextOne {
              color: #f1c602;
            }
            .couponTextTwo {
              color: #0ea3ed;
            }
            .couponTextThree {
              color: #2778ec;
            }
            .couponTextFour {
              color: #e98740;
            }
          }
        }
        .couponBorderOne {
          border: 0.01rem solid #f1c602;
        }
        .couponBorderTwo {
          border: 0.01rem solid #0ea3ed;
        }
        .couponBorderThree {
          border: 0.01rem solid #2778ec;
        }
        .couponBorderFour {
          border: 0.01rem solid #e98740;
        }
      }
    }
    // 已使用的背景色
    .commonBak {
      // background-color: pink;
    }

    .box_4.expired::before {
      content: ''; /* 伪元素内容为空 */
      position: absolute; /* 绝对定位 */
      top: 0; /* 与父元素顶部对齐 */
      left: 0; /* 与父元素左边对齐 */
      width: 100%; /* 宽度为父元素宽度 */
      height: 100%; /* 高度为父元素高度 */
      background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
      z-index: 1; /* 遮罩层位于内容之上 */
    }

    .box_4.expired span {
      position: relative; /* 设置为相对定位，以便内容在遮罩之上显示 */
      z-index: 2; /* 内容位于遮罩之上 */
    }
    .overDate{
 opacity: 0.5
    }
    .couponTypeOne {
      background: linear-gradient(to right, #caddff, #eef6ff);

    }

    .couponTypeTwo {
      background: linear-gradient(to right, #abe3ff, #bee2f4);

    }
    .couponTypeThree {
      background: linear-gradient(to right, #fff4c1, #fff8d9);

    }

    .couponTypeFour {
      background: linear-gradient(to right, #ffdbca, #fff7ee);

    }
  }
}

/deep/ .van-tab__pane,
.van-tab__pane-wrapper {
  display: flex;
  flex-direction: column;
}
// /deep/ .van-tabs__wrap {

/deep/ .van-tabs__nav,
.van-tabs__nav--line {
  background-color: #f8f7fc !important;
}
// }
/deep/ .van-tabs__content {
  background-color: #fff;
  margin-top: 0.16rem;
  padding: 0.08rem;
}
</style>
